import React, { Component } from 'react'
import { graphql } from 'gatsby'
// components
import Layout from '../components/layouts'
import WriteToUs from '../components/pages/main/WriteToUs'
import Products from '../components/pages/main/Products'
import Solutions from '../components/pages/main/Solutions'
import Services from '../components/pages/main/Services'
import Bkad from '../components/pages/main/Bkad'
import Projects from '../components/pages/main/Projects'
import Partners from '../components/pages/main/Partners'
import Clients from '../components/pages/main/Clients'
import Contacts from '../components/pages/main/Contacts'

export default class Main extends Component {

  componentDidMount() {
    document.body.style.background = "#fff url('/static/images/background-main.jpg') no-repeat"
    document.body.style.backgroundSize = 'cover'
  }

  getSectionData = (section) => {
    const edges = this.props.data.allMarkdownRemark ? 
      this.props.data.allMarkdownRemark.edges 
      : []

    let decorateNode = {
      frontmatter: {
        title: null
      },
      html: null,
      childrens: []
    }

    edges.forEach(edge => {
      let node = edge.node
      if (node.frontmatter.section === section) {
        if (node.frontmatter.main) {
          decorateNode = {
            frontmatter: node.frontmatter,
            html: node.html,
            childrens: []
          }
        } else {
          decorateNode.childrens.push({ ...node })
        }
      }
    })
    return decorateNode
  }

  render() {
    return (
      <Layout>
        <WriteToUs data={this.getSectionData('writeToUs')} />
        <Products data={this.getSectionData('products')} />
        <Solutions data={this.getSectionData('solutions')} />
        <Services data={this.getSectionData('services')} />
        <Bkad data={this.getSectionData('bkad')} />        
        <Projects data={this.getSectionData('projects')} />
        <Partners data={this.getSectionData('partners')} />
        <Clients data={this.getSectionData('clients')} />
        <Contacts data={this.getSectionData('contacts')} />
      </Layout>
    )
  }
}

export const query = graphql`
  query getHomeData {
    allMarkdownRemark (
      filter: {
        frontmatter: {page: {eq: "home"}}
      }
      sort: {fields: [frontmatter___main], order: ASC}
    ) {
      edges {
        node {
          frontmatter {
            page
            section
            title
            index
            main
            block
          }
          html
        }
      }
    }
  }
` 