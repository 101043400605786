import React from 'react';
import { ShowALl, Inner } from './linklist.styled';

export default class LinkList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAll: false };
  }

  getList = () => {
    if (this.props.items && this.props.items.length) {
      const partners = this.props.items.sort((firstRow, secondRow) => {
        return firstRow.frontmatter.index - secondRow.frontmatter.index;
      });

      let logos = '';

      if (!this.state.showAll) {
        logos = partners[0].html + partners[1].html;
        return <Inner dangerouslySetInnerHTML={{ __html: logos }} />;
      } else {
        partners.forEach((partnersRow) => {
          logos += partnersRow.html;
        });
        return <Inner dangerouslySetInnerHTML={{ __html: logos }} />;
      }
    }
  };

  render() {
    const { showAll } = this.props;

    return (
      <>
        {this.getList()}
        {showAll && (
          <ShowALl onClick={() => this.setState({ showAll: !this.state.showAll })}>
            {this.state.showAll ? 'Скрыть' : 'Показать все'}
          </ShowALl>
        )}
      </>
    );
  }
}
