import React, { Component } from 'react'
import styled from 'styled-components'
//import { H1 } from '../../buttons/styledComponents'

import { MainButton as Button } from '../../buttons/styledComponents'
import Form from '../../forms/Feedback'
import withModal from '../../forms/withModal'

const ModalForm = withModal(Form)

const Container = styled.section`
  width: 688px;
  margin-top: 250px;
  @media (max-width: 1129px) {
    width: 70%;    
    margin-top: 150px;
  }
  @media (max-width: 991px) {
    width: auto;
    margin-top: 75px;
  }

`
const Title = styled.h1`
  margin-bottom: 37px;
  @media (max-width: 991px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
`
const Inner = styled.div`
  p {
    font-size: 18px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      font-size: 14px;
      margin-bottom: 1.5rem;
    }
  }
`

export default class WriteToUs extends Component {
  state = { showForm: false }

  showForm = () => {
    this.setState({ showForm: true })
  }
  closeForm = () => {
    this.setState({ showForm: false })
  }

  render() {
    const { frontmatter, html } = this.props.data
    return (
      <Container>
        <Title>{frontmatter.title}</Title>
        <Inner dangerouslySetInnerHTML={{ __html: html }} />
        <Button onClick={this.showForm}>Написать нам</Button>
        {this.state.showForm ? <ModalForm close={this.closeForm}/> : null}
      </Container>
    )
  }
}