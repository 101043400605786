import React, { Component } from 'react'
import styled from 'styled-components'

import { Background } from './styledComponents'

const StyledBackground = styled(Background)`
  & > div {
    z-index: 12;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
` 
function withModal (WrappedCopmponent) {
  return class extends Component {

    componentDidMount() {
      window.addEventListener('wheel', this.onWheel)
    }
    componentWillUnmount() {
      window.removeEventListener('wheel', this.onWheel)
    }

    onWheel = (e = window.event) => {
      e.preventDefault()
    }

    render() {

      return (
        <StyledBackground>
          <WrappedCopmponent {...this.props } />
        </StyledBackground>
      )
    }
  }
}

export default withModal