import React, { Component } from 'react';
import styled from 'styled-components';
import Tabs from '../../../components/styledComponents/tabs';
import { H1 } from '../../buttons/styledComponents'

const Container = styled.section`
  margin-top: 145px;
  @media (max-width: 1129px) {
    margin-top: 50px;
  }   
`
const Title = styled(H1)`
  margin-bottom: 18px;
`
const SubTitle = styled.h3`
  margin: 0 0 1rem;
`
const SectionInfo = styled.p`
  margin: 0 0 3rem;
  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }   

`

export default class Bkad extends Component {

    render() {
        const { frontmatter, childrens } = this.props.data

        return (
            <Container id="bkad">
                <Title>{frontmatter.title}</Title>
                <SubTitle>Умные дороги в городских агломерациях</SubTitle>
                <SectionInfo>Внедрение интеллектуальных транспортных систем, предусматривающих автоматизацию процессов управления дорожным движением, в городских агломерациях, включающих города с населением свыше 300 тысяч человек.</SectionInfo>
                <Tabs items={childrens} selected="bkad1" />
            </Container>
        )
    }
}