import React from 'react';
import styled from 'styled-components';
import { H1 } from '../../buttons/styledComponents';

const Wrapper = styled.div`
  margin-top: ${(props) => (props.$single ? '50px' : '150px')};
  left: 0;
  width: 100%;
  height: 815px;
  background: linear-gradient(#ee6940, #e15e36);

  @media (max-width: 1129px) {
    margin-top: 50px;
  }
  @media (max-width: 991px) {
    margin: 1rem -15px 0;
    height: auto;
    width: auto;
  }

  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    background: linear-gradient(#ee6940, #e15e36);
    width: 100%;
    height: inherit;
    left: 0;
  }
`;
const Container = styled.section`
  height: 100%;
  width: 1110px;
  margin: 0 auto;
  padding: 50px 0;
  @media (max-width: 1129px) {
    width: 100%;
  }
  @media (max-width: 991px) {
    padding: 15px;
  }
`;
const TitleImg = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 55px;
  ${H1} {
    color: #fff;
    margin: 0 20px 0 0;
  }
  @media (max-width: 991px) {
    margin-bottom: 25px;
  }
`;
const Inner = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      position: relative;
      color: #fff;
      width: 550px;
      @media (max-width: 1129px) {
        width: 50%;
      }
      @media (max-width: 991px) {
        width: 100%;
      }
      li {
        padding-left: 26px;
        &:before {
          content: '— ';
          position: absolute;
          left: 2px;
        }
      }
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;
        @media (max-width: 1129px) {
          font-size: 20px;
        }
      }
      div {
        font-size: 16px;
        line-height: 1.56;
        @media (max-width: 1129px) {
          font-size: 14px;
        }
      }
      p {
        position: absolute;
        a {
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            right: -14px;
            top: 5px;
            width: 8px;
            height: 8px;
            transform: rotate(-45deg);
            border-bottom: solid 2px #fff;
            border-right: solid 2px #fff;
            @media (max-width: 991px) {
              border: 0;
            }
          }
        }
      }
    }
  }
  #solutions-list > li:nth-child(1) {
    height: 265px;
    border-right: 1px solid #d74e25;
    border-bottom: 1px solid #d74e25;
    padding-bottom: 50px;
    @media (max-width: 991px) {
      height: auto;
      border: 0;
    }

    p {
      bottom: 50px;
    }
    & + li {
      border-bottom: 1px solid #d74e25;
      padding: 0 0 50px 30px;
      p {
        bottom: 50px;
      }
    }
  }
  #solutions-list > li:nth-child(3) {
    height: 342px;
    padding: 50px 0 0 0px;
    border-right: 1px solid #d74e25;
    @media (max-width: 991px) {
      height: auto;
      border: 0;
    }

    p {
      bottom: 0;
    }
    & + li {
      padding: 50px 0 0 30px;
      p {
        bottom: 0;
      }
    }
  }
`;

const Solutions = ({ data, single }) => {
  const { frontmatter, html } = data;

  return (
    <Wrapper id="solutions" $single={single}>
      <Container>
        {!single && (
          <TitleImg>
            <H1>{frontmatter.title}</H1>
            <img src="/static/images/icon-solutions.png" alt="Решения" />
          </TitleImg>
        )}
        <Inner dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Wrapper>
  );
};

export default Solutions;
