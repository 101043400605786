import React from 'react'
import styled from 'styled-components'
import { H1 } from '../../buttons/styledComponents'
import { ProductsBlock } from '../../styledComponents/products'

const Container = styled.section`
  margin-top: 180px;
  @media (max-width: 1129px) {
    margin-top: 100px;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }    

`
const Title = styled(H1)`
  margin-bottom: 32px;
  @media (max-width: 991px) {
    margin-bottom: 1rem;
    font-size: 20px;
  }    
`
const Products = ({data}) => {
  const { frontmatter, html } = data

  return (
    <Container>
      <Title>{frontmatter.title}</Title>
      <ProductsBlock dangerouslySetInnerHTML={{ __html: html }}/>
    </Container>
  )
}

export default Products