import React from 'react';
import styled from 'styled-components';
import LinkList from '../../../components/styledComponents/linklist';
import { H1 } from '../../buttons/styledComponents'

const Container = styled.section`
  margin-top: 190px;
  @media (max-width: 1129px) {
    margin-top: 80px;
  } 
`
const Title = styled(H1)`
  margin-bottom: 50px;
`

const Clients = ({ data }) => {
    const { frontmatter, childrens } = data

    return (
        <Container>
            <Title>{frontmatter.title}</Title>
            <LinkList items={childrens} showAll={false} />
        </Container>
    )
}

export default Clients