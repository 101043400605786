import React from 'react'
import styled from 'styled-components'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import { H1 } from '../../buttons/styledComponents'

const mapState = { center: [59.942624, 30.364153], zoom: 16 }

const Container = styled.section`
  margin-top: 155px;
  @media (max-width: 1129px) {
    margin-top: 80px;
  } 
  @media (max-width: 991px) {
    margin-top: 30px;
  }   
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }   
`
const Title = styled(H1)`
  @media (max-width: 991px) {
      font-size: 24px;
  }   
`
const Inner = styled.div`
  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  @media (max-width: 991px) {
    p{
      font-size: 14px;
      line-height: normal;
    }
  }   
  
`
const MapContainer = styled.div`
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
`

export default class Contacts extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          height: 830
      }
  }

  componentDidMount() {
    this.setState({height: window.innerWidth < 1129 ? 400 : 830})
  }

  render() {
    const { height } = this.state;  
    const { frontmatter, html } = this.props.data;
  
    return (
      <Container>
        <TitleWrapper>
          <Title>{frontmatter.title}</Title>
          <Inner dangerouslySetInnerHTML={{ __html: html }} />
        </TitleWrapper>
        <MapContainer>
          <YMaps>
            <Map state={mapState} width={-1} height={height} instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom') }}>
              <Placemark
                geometry={{
                  type: 'Point',
                  coordinates: [59.942624, 30.364153]
                }}
                properties={{
                  iconContent: 'ул. Радищева, д. 39, литера Д, офис 312'
                }}
                options={{
                  preset: 'islands#blueStretchyIcon'
                }}
              />
            </Map>
          </YMaps>
        </MapContainer>
      </Container>
    );
  }
}
