import React, { Component } from 'react'
import styled from 'styled-components'
import { H1 } from '../../buttons/styledComponents'

const Container = styled.section`
  margin-top: 280px;
  @media (max-width: 1129px) {
    margin-top: 50px;
  } 
`
const Title = styled(H1)`
  margin-bottom: 35px;
`
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;
`
const Item = styled.li`
  width: 515px;
  @media (max-width: 1129px) {
    width: 48%;
  }  
  @media (max-width: 991px) {
    width: 100%;
  }   
`
const Inner = styled.div`
  margin-bottom: 30px;
  .title {
    margin-bottom: 18px;
    width: 515px;
    position: relative;
    @media (max-width: 1129px) {
      width: auto;
    }     
    img {
      border-radius: 4px;
      height: 297px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
    }
    div {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      bottom: 4px;
      width: 100%;
      background-color: rgba(49, 49, 49, 0.9);
      padding: 20px 45px;
      h2 {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.8;
        color: #fff;
        @media (max-width: 1129px) {
          font-size: 18px;
        } 
      }
      a {
        font-size: 14px;
        font-weight: 600;
        color: #fc5b0e;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          right: -14px;
          top: 5px;
          width: 8px;
          height: 8px;
          transform: rotate(-45deg);
          border-bottom: solid 2px #fc5b0e;
          border-right: solid 2px #fc5b0e;
        }
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 1.44;
    color: #000000;
  }
`
// const ShowALl = styled.p`
//   cursor: pointer;
//   width: fit-content;
//   font-size: 14px;
//   font-weight: 600;
//   margin: 0 auto;
//   color: #fc5b0e;
// `

export default class Projects extends Component {
  constructor() {
    super()
    this.minProjectCount = 4
    this.state = { displayedProjects: this.minProjectCount }
  }

 
  getProjects = () => {
    const projects = this.props.data.childrens.sort((firstChild, secondChild) => {
      return firstChild.frontmatter.index - secondChild.frontmatter.index
    })

    return projects.map((project, index) => {
      return (
        ++index <= this.state.displayedProjects ?
        <Item key={index}>
          <Inner dangerouslySetInnerHTML={{ __html: project.html }}/>
        </Item>
        : null
      )
    }) 
  }

  displayToggle = () => {
    const { childrens } = this.props.data
    this.state.displayedProjects > this.minProjectCount ? 
      this.setState({ displayedProjects: this.minProjectCount })
      :
      this.setState({ displayedProjects: childrens.length })
  }

  render() {
    const { frontmatter } = this.props.data
    return (
      <Container>
        <Title>{frontmatter.title}</Title>
        <List>
          {this.getProjects()}
        </List>
        {/* <ShowALl onClick={this.displayToggle}>
          {
            this.state.displayedProjects > this.minProjectCount ?
            'Скрыть проекты'
            : 
            'Показать все проекты'
          }
        </ShowALl> */}
      </Container>
    )
  }
}
