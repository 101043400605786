import React, { Component } from 'react'

import { Container, Close, Submit } from './styledComponents'
import Field from './field'
import ResponseWindow from './ResponseWindow'

export default class Partners extends Component {
  state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    company: '',
    errors: [],
    responseMsg: null
  }

  setValue = (field, value) => {
    this.setState({ [field]: value })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const values = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      company: this.state.company,
      message: this.state.message
    }

    const errors = window.validate(values)
    if (errors) {
      this.setState({ errors: errors })
    } else {
      window.sendMail(values,
        (res) => {
          console.log(res)
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }

  render() {
    return (
      <>
        {
          !this.state.responseMsg ?
            <Container>
              <Close onClick={this.props.close} />
              <h1>Стать партнером</h1>
              <p>
                Обращайтесь к нам по телефону, электронной
                почте или при помощи данной формы связи
          </p>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <Field
                  field='name'
                  label='Ваше имя *'
                  placeholder='Иванов Иван Иванович'
                  value={this.state.name}
                  setValue={this.setValue}
                  errors={this.state.errors}
                />
                <Field
                  field='email'
                  label='Email *'
                  placeholder='ivanovi@gmail.com'
                  value={this.state.email}
                  setValue={this.setValue}
                  errors={this.state.errors}
                />
                <Field
                  field='phone'
                  label='Телефон'
                  placeholder='+7 (___) ___-__-__'
                  value={this.state.phone}
                  setValue={this.setValue}
                />
                <Field
                  field='company'
                  label='Компания'
                  placeholder='ООО «Организация»'
                  value={this.state.company}
                  setValue={this.setValue}
                />
                <Field
                  field='message'
                  label='Сообщение или вопрос'
                  placeholder='Введите текст'
                  value={this.state.message}
                  setValue={this.setValue}
                />
                <Submit>Отправить</Submit>
              </form>
            </Container>
            : <ResponseWindow type={this.state.responseMsg} close={this.props.close} />
        }
      </>
    )
  }
}