import React, { Component } from 'react'
import styled from 'styled-components'
import LinkList from '../../../components/styledComponents/linklist';
import { MainButton } from '../../buttons/styledComponents'

import Form from '../../forms/Partners'
import withModal from '../../forms/withModal'
import { H1 } from '../../buttons/styledComponents'

const ModalForm = withModal(Form)
export const Container = styled.section`
  margin-top: 140px;
  @media (max-width: 1129px) {
    margin-top: 20px;
  }   
`
export const Title = styled(H1)`
  margin-bottom: 50px;
`
export const Partnership = styled.div`
  margin-top: 83px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
      margin-top: 1rem;
  }
  p {
    font-size: 16px;
    line-height: 1.69;
    color: #000000;
    margin-bottom: 25px;
    @media (max-width: 991px) {
      font-size: 14px;
    }  
  } 
`
export const Button = styled(MainButton)`
  margin: 0 auto;
`

export default class Projects extends Component {
    state = { showForm: false }

    showForm = () => {
        this.setState({ showForm: true })
    }
    closeForm = () => {
        this.setState({ showForm: false })
    }

    render() {
        const { frontmatter, html } = this.props.data
        return (
            <Container>
                <Title>{frontmatter.title}</Title>
                <LinkList items={this.props.data.childrens} showAll={true} />
                <Partnership dangerouslySetInnerHTML={{ __html: html }} />
                <Button onClick={this.showForm}>Стать партнером</Button>
                {this.state.showForm ? <ModalForm close={this.closeForm} /> : null}
            </Container>
        )
    }
}