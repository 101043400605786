import styled from 'styled-components';

export const Inner = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  ul {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media (max-width: 991px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }  

    li {
      filter: grayscale(90%) opacity(85%);
      @media (max-width: 991px) {
          margin: 0 1rem 1rem 0;
          & img{
              max-height: 25px;
          }
      }  

      &:hover {
        filter: none;
      }
    }
  }
`
export const ShowALl = styled.p`
  cursor: pointer;
  width: fit-content;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto 15px;
  color: #fc5b0e;
`
