import React, { Component } from 'react';
import styled from 'styled-components';
import Tabs from '../../../components/styledComponents/tabs';
import { H1 } from '../../buttons/styledComponents';

export const Container = styled.section`
  margin-top: 145px;
  @media (max-width: 1129px) {
    margin-top: 50px;
  }
`;
export const Title = styled(H1)`
  margin-bottom: 18px;
`;

export default class Services extends Component {
  render() {
    const { frontmatter, childrens } = this.props.data;

    return (
      <Container id="services">
        <Title>{frontmatter.title}</Title>
        <Tabs items={childrens} selected="integration" />
      </Container>
    );
  }
}
